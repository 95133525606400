
const config = {
  NavBar : {
    NavRouteList: [
      {key:"home", title: "主页", url: "/#/"},
      {key:"intro", title: "社团介绍", url: "/#/intro/"},
      {key:"projects", title: "企划", url: "/#/projects/"},
    ],
    OutsiteLinkList: [
      {key:"fl_zry", title: "ZRY的个人网站", url: "http://swzry.com/"},
      {key:"fl_nya", title: "塔之地下室", url: "http://nya.ac.cn/"},
      {key:"fl_uduki", title: "卯月的糖果屋", url: "https://mao-yue.github.io/"},
    ],
  }
}
export default {
  config
}
