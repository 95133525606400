import { render, staticRenderFns } from "./PageFrame.vue?vue&type=template&id=2114eb3a&scoped=true&"
import script from "./PageFrame.vue?vue&type=script&lang=js&"
export * from "./PageFrame.vue?vue&type=script&lang=js&"
import style0 from "./PageFrame.vue?vue&type=style&index=0&id=2114eb3a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2114eb3a",
  null
  
)

export default component.exports