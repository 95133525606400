import Vue from 'vue'
import Router from 'vue-router'

import ProjectsInfo from '@/datasrc/projects.list.js'

const Home = () => import("@/views/Home.vue")
const Intro = () => import("@/views/Intro.vue")
const Projects = () => import("@/views/Projects.vue")
const NotFound = () => import("@/views/NotFound.vue")

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: "主页"
      }
    },
    {
      path: '/intro',
      name: 'intro',
      component: Intro,
      meta: {
        title: "社团介绍"
      }
    },
    {
      path: '/projects',
      name: 'projects',
      component: Projects,
      meta: {
        title: "企划"
      },
      children: ProjectsInfo.getRouteChildren()
    },
    {
      path: '*',
      name: 'notfound',
      component: NotFound,
      meta: {
        title: "Not Found"
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + " - Z神社 公式站"
  }else{
    document.title = "Z神社 公式站"
  }
  next()
})

export default router