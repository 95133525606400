<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    variant="tmprimary"
  >
    <b-navbar-brand href="/#/">
      <img
        src="@/assets/logo_navbar.png"
        id="img_navbar_logo"
      />
    </b-navbar-brand>
    <b-navbar-brand href="/#/"><span id="navbar_title_span">Z神社 公式站</span></b-navbar-brand>
    <b-navbar-nav>
      <b-nav-item
        v-for="item in navLink"
        :key="item.key"
        :href="item.url"
        :class="{navbar_link: true}"
      >{{item.title}}</b-nav-item>
      <b-nav-item-dropdown :class="{navbar_link: true}">
        <template slot="button-content">友情链接</template>
        <b-dropdown-item
          v-for="item in navOutsiteLink"
          :key="item.key"
          :href="item.url"
          target="_blank"
        >{{item.title}}</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HeaderNav",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["navLink", "navOutsiteLink"])
  }
};
</script>

<style scoped>
#img_navbar_logo {
  height: 48px;
}

#navbar_title_span {
  font-size: 24px;
}

.navbar_link {
  font-size: 20px;
}
</style>
