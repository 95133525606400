const C_ROOT = () => import("@/views/v_projects/Root.vue")
const C_OHF = () => import("@/views/v_projects/OHF.vue")
const C_YV8R0 = () => import("@/views/Constructing.vue")
const C_GIP = () => import("@/views/Constructing.vue")

const projectList = [
    {key: "root", title: "企划主页", subroute: "", component: C_ROOT},
    {key: "ohf", title: "OHF Project", subroute: "ohf", component: C_OHF},
    {key: "yv8r0", title: "Yakumo V8R0", subroute: "yv8r0", component: C_YV8R0},
    {key: "gip", title: "幻想乡工业化计划", subroute: "gip", component: C_GIP},
]

const inf = {
    getRouteChildren(){
        var crl = []
        projectList.forEach(iv => {
            crl.push({path: iv.subroute, name: "projects_" + iv.key, component: iv.component})
        });
        return crl
    },
    getRenderList(current_url){
        var rl = []
        console.log(current_url)
        projectList.forEach(iv => {
            var isActive = (current_url == "/projects/" + iv.subroute)
            rl.push({key: iv.key, title: iv.title, url: "/#/projects/" + iv.subroute, isActive: isActive})
        });
        return rl
    }
}

export default inf